import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode, useState } from 'react';
import { XClose } from '@untitled-ui/icons-react';
import { CustomSelect } from '@/components/custom-select';
import { BulkActionType } from '@/components/application/bulk-action/bulk-action.type';
import { BulkPlacementDecisionForm } from '@/components/application/bulk-action/bulk-placement-decision.form';
import { BulkMessageApplicantsForm } from '@/components/application/bulk-action/bulk-message-applicants.form';
import { BulkProgressApplicationsForm } from './bulk-progress-applications.form';
import { useTerms } from '@/hooks/query-hooks/use-terms';
import { Spinner } from '@/components/spinner';
import { Alert } from '@/components/alert';
import { useCohorts } from '@/hooks/query-hooks/use-cohorts';
import { BulkExportApplicationsForm } from './bulk-export-applications.form';
import { LocationType } from '@admissions-support/types';
import { startCase } from 'lodash-es';
import { InviteToSchoolForm } from './bulk-invite-to-school.form';

type ExportApplicantsProps = {
  applicantIds: string[];
  onClose: () => void;
  applicationsType: LocationType;
};

function ExportApplications({
  applicantIds,
  onClose,
  applicationsType,
}: ExportApplicantsProps) {
  return (
    <BulkExportApplicationsForm
      applicantIds={applicantIds}
      onClose={onClose}
      applicationsType={applicationsType}
    />
  );
}

type MessageApplicantsProps = {
  applicantIds: string[];
  onClose: () => void;
  applicationsType: LocationType;
};

function MessageApplicants({
  applicantIds,
  onClose,
  applicationsType,
}: MessageApplicantsProps) {
  return (
    <BulkMessageApplicantsForm
      applicantIds={applicantIds}
      onClose={onClose}
      applicationsType={applicationsType}
    />
  );
}

type ProgressApplicationsProps = {
  applicantIds: string[];
  onClose: () => void;
  applicationsType: LocationType;
};

function ProgressApplications({
  applicantIds,
  onClose,
  applicationsType,
}: ProgressApplicationsProps) {
  const {
    data: schoolYears,
    isLoading: isSchoolYearsLoading,
    isError: isSchoolYearsError,
    isSuccess: isSchoolYearsSuccess,
  } = useTerms();

  const {
    data: cohorts,
    isLoading: isCohortsLoading,
    isError: isCohortsError,
    isSuccess: isCohortsSuccess,
  } = useCohorts();

  const isLoading = isSchoolYearsLoading || isCohortsLoading;
  const isError = isSchoolYearsError || isCohortsError;
  const isSuccess = isSchoolYearsSuccess && isCohortsSuccess;

  if (isLoading) {
    return <Spinner className="m-auto" />;
  }

  if (isError || !isSuccess) {
    return (
      <Alert
        type="error"
        text="Something unexpected happened, please try again!"
        title="Error!"
      />
    );
  }

  return (
    <BulkProgressApplicationsForm
      applicantIds={applicantIds}
      onClose={onClose}
      cohorts={cohorts}
      schoolYears={schoolYears}
      applicationsType={applicationsType}
    />
  );
}

type PlacementDecisionProps = {
  applicantIds: string[];
  onClose: () => void;
  applicationsType: LocationType;
};

function PlacementDecision({
  applicantIds,
  onClose,
  applicationsType,
}: PlacementDecisionProps) {
  return (
    <BulkPlacementDecisionForm
      applicationsType={applicationsType}
      applicantIds={applicantIds}
      onClose={onClose}
    />
  );
}

type InviteToSchoolProps = {
  applicantIds: string[];
  onClose: () => void;
  applicationsType: LocationType;
};

function InviteToSchool({
  applicantIds,
  onClose,
  applicationsType,
}: InviteToSchoolProps) {
  return (
    <InviteToSchoolForm
      applicationsType={applicationsType}
      applicantIds={applicantIds}
      onClose={onClose}
    />
  );
}

const getInviteSchoolType = (type: LocationType) => {
  switch (type) {
    case 'PRIMARY':
      return 'SECONDARY';
    case 'ELC':
      return 'PRIMARY';
    case 'SECONDARY':
      return '';
    default:
      throw new Error(`Unsupported application type: ${type}`);
  }
};

type BulkActionModalProps = {
  open: boolean;
  onClose: () => void;
  selectedApplicantIds: string[];
  applicationsType: LocationType;
};

function BulkActionModal(props: BulkActionModalProps) {
  const { open, onClose, selectedApplicantIds, applicationsType } = props;
  const [selectedBulkAction, setSelectedBulkAction] =
    useState<BulkActionType | null>(null);

  const bulkActionOptions: {
    key: BulkActionType;
    value: string;
    isVisible: boolean;
  }[] = [
    {
      key: 'placementDecision',
      value: 'Placement Decision',
      isVisible: true,
    },
    {
      key: 'messageApplicants',
      value: 'Message Applicants',
      isVisible: true,
    },
    {
      key: 'exportApplicantsCsv',
      value: 'Export Applications',
      isVisible: true,
    },
    {
      key: 'progressApplications',
      value: 'Progress Applications',
      isVisible: true,
    },
    {
      key: 'inviteToSchool',
      value: `Invite to Submit ${startCase(
        getInviteSchoolType(applicationsType).toLowerCase()
      )} School Application`,
      isVisible: applicationsType !== 'SECONDARY',
    },
  ];

  const handleClose = () => {
    setSelectedBulkAction(null);
    onClose();
  };

  const bulkActionToComponentMap: Record<BulkActionType, ReactNode> = {
    placementDecision: (
      <PlacementDecision
        applicantIds={selectedApplicantIds}
        onClose={handleClose}
        applicationsType={applicationsType}
      />
    ),
    messageApplicants: (
      <MessageApplicants
        applicantIds={selectedApplicantIds}
        onClose={handleClose}
        applicationsType={applicationsType}
      />
    ),
    progressApplications: (
      <ProgressApplications
        applicantIds={selectedApplicantIds}
        onClose={handleClose}
        applicationsType={applicationsType}
      />
    ),

    exportApplicantsCsv: (
      <ExportApplications
        applicantIds={selectedApplicantIds}
        onClose={handleClose}
        applicationsType={applicationsType}
      />
    ),
    inviteToSchool: (
      <InviteToSchool
        applicantIds={selectedApplicantIds}
        onClose={handleClose}
        applicationsType={applicationsType}
      />
    ),
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-60" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="flex w-full justify-between gap-4">
                  <span className="text-lg font-semibold text-primary-900">
                    Apply Actions
                  </span>
                  <button
                    type="button"
                    className="focus:ring-indigo-500 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XClose className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-9">
                  <CustomSelect
                    name={'bulkAction'}
                    label={'Bulk Action'}
                    options={bulkActionOptions.filter(
                      option => option.isVisible
                    )}
                    value={''}
                    onChange={data =>
                      setSelectedBulkAction(data as BulkActionType)
                    }
                  />

                  {selectedBulkAction ? (
                    <div className="mt-5">
                      <div className="flex flex-col gap-2">
                        {bulkActionToComponentMap[selectedBulkAction] || null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { BulkActionModal };
