import { staffRouterPath } from '@/config/route-paths.config';
import { ButtonGroup, ButtonGroupBase } from '../common/button-group';
import { TextInput } from '../common/text-input';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { LocationFormData } from '../location.form';
import { SchoolLocationFormData } from '../school-location.form';

type ProviderTypeValuesOption = { key: string; value: string };

const providerTypeValues: Record<string, ProviderTypeValuesOption[]> = {
  ELC: [
    {
      key: 'COUNCIL',
      value: 'Council',
    },
    {
      key: 'PARTNER_PROVIDER',
      value: 'Partner Provider',
    },
    {
      key: 'CHILD_MINDER',
      value: 'Child Minder',
    },
  ],
  PRIMARY: [
    {
      key: 'NON_DENOMINATIONAL',
      value: 'Non-Denominational',
    },
    {
      key: 'ROMAN_CATHOLIC',
      value: 'Denominational',
    },
  ],
  SECONDARY: [
    {
      key: 'NON_DENOMINATIONAL',
      value: 'Non-Denominational',
    },
    {
      key: 'ROMAN_CATHOLIC',
      value: 'Denominational',
    },
  ],
};

type LocationDetailsSectionProps = {
  isSchoolLocation?: boolean;
  isEdit?: boolean;
};

function LocationDetailsSection(props: LocationDetailsSectionProps) {
  const { isEdit, isSchoolLocation } = props;

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const form = useFormContext<LocationFormData | SchoolLocationFormData>();

  const type = searchParams.get('type') || 'ELC';

  const onTypeChange = (newVal: string) => {
    if (newVal === 'ELC') {
      const url = isEdit
        ? staffRouterPath.EDIT_LOCATION
        : staffRouterPath.NEW_LOCATION;
      navigate(`${url}?type=${newVal}`, { state: { ...form.getValues() } });
      return;
    }

    const url = isEdit
      ? staffRouterPath.EDIT_SCHOOL_LOCATION
      : staffRouterPath.CREATE_SCHOOL_LOCATION;
    navigate(`${url}?type=${newVal}`, { state: { ...form.getValues() } });
  };

  return (
    <div className="two-col-form">
      <div>
        <p className="text-md font-medium leading-7 text-gray-900">
          Location Details
        </p>
        <p className="text-md leading-6 text-gray-600">
          Specify location details
        </p>
      </div>
      <div className="col-span-2 space-y-6">
        <TextInput
          type="text"
          name="name"
          label="Location Name*"
          placeholder="Little Nippers"
        />

        {isEdit ? (
          !isSchoolLocation && (
            <ButtonGroup
              name="providerType"
              label="Provider Type*"
              options={providerTypeValues[type]}
            />
          )
        ) : (
          <>
            <ButtonGroupBase
              label="Type*"
              options={[
                {
                  key: 'ELC',
                  value: 'ELC',
                },
                {
                  key: 'PRIMARY',
                  value: 'Primary',
                },
                {
                  key: 'SECONDARY',
                  value: 'Secondary',
                },
              ]}
              defaultValue={type}
              onChange={onTypeChange}
            />

            <ButtonGroup
              name="providerType"
              label="Provider Type*"
              options={providerTypeValues[type]}
            />
          </>
        )}

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          {type === 'ELC' && !isSchoolLocation && (
            <>
              <TextInput
                type="text"
                name="serviceProviderNumber"
                label="Service Provider Number*"
                placeholder="111222333444"
              />
              <TextInput
                type="text"
                name="careInspectorNumber"
                label="Care Inspectorate Number*"
                placeholder="111222333444"
              />
            </>
          )}
          <TextInput
            type="text"
            name="contactNumber"
            label={`Contact Number${isSchoolLocation ? '*' : ''}`}
            placeholder="0123 345679"
          />
          <TextInput
            type="text"
            name="website"
            label="Website"
            placeholder="https://example.com"
          />
          <TextInput
            type="text"
            name="address.streetAddress"
            label="Address Line 1*"
            placeholder="1 Main Street"
          />
          <TextInput
            type="text"
            name="address.streetAddressTwo"
            label="Address Line 2"
            placeholder="Floor 10"
          />
          <TextInput
            type="text"
            name="address.city"
            label="City"
            placeholder="Edinburgh"
          />
          <TextInput
            type="text"
            name="address.postcode"
            label="Postcode*"
            placeholder="EH1 3LA"
          />
          <TextInput
            type="text"
            name="seedCode"
            label={`Seed Code${!isSchoolLocation ? '*' : ''}`}
            placeholder="5501620"
          />
        </div>
      </div>
    </div>
  );
}

export { LocationDetailsSection };
